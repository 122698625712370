@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MarcellusRegular";
  src: local("MarcellusRegular"),
    url("/fonts/Marcellus/Marcellus-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
    url("/fonts/Outfit/Outfit-VariableFont_wght.ttf") format("truetype");
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  scrollbar-gutter: stable;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
  z-index: 999999;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  border: 0px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.AreaHighlight {
  border: 1px solid #333;
  background-color: rgba(252, 232, 151, 1);
  opacity: 1;
  mix-blend-mode: multiply;
}

.AreaHighlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(173, 181, 255, 0.4);
  transition: background 0.3s;
}

.AreaHighlight--scrolledTo .AreaHighlight__part {
  background: rgba(173, 181, 255, 0.4);
}

.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.Highlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(173, 181, 255, 0.4);
  transition: background 0.3s;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
  background: rgba(173, 181, 255, 0.4);
}

.MouseSelection {
  position: absolute;
  border: 1px dashed #333;
  background: rgba(173, 181, 255, 0.4);
  mix-blend-mode: multiply;
}
/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .MouseSelection {
    opacity: 0.5;
  }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align: auto) {
  .MouseSelection {
    opacity: 0.5;
  }
}

/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
  mix-blend-mode: multiply;
  display: flex;
}

.annotationLayer {
  position: absolute;
  top: 0;

  z-index: 3;
}

html
  body
  .textLayer
  > div:not(.PdfHighlighter__highlight-layer):not(
    .PdfHighlighter__table-layer
  ):not(.Highlight):not(.Highlight-emoji) {
  opacity: 1;
  mix-blend-mode: multiply;
}

.textLayer ::selection {
  background: rgba(173, 181, 255, 0.4);
  mix-blend-mode: multiply;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {
    opacity: 0.5;
  }
}

/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {
    opacity: 0.5;
  }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align: auto) {
  .textLayer {
    opacity: 0.5;
  }
}

.PdfHighlighter {
  position: absolute;
  overflow: auto;
  height: calc(100% - 72px);
}

.PdfHighlighter__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__table-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__tip-container {
  z-index: 6;
  position: absolute;
}

.PdfHighlighter--disable-selection {
  user-select: none;
  pointer-events: none;
}

.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 10px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
}

.Tip__card textarea {
  font-size: 16px;
  width: 200px;
  height: 70px;
}

.Tip__card input[type="submit"] {
  margin-top: 5px;
  font-size: large;
}

#typeahead-menu {
  z-index: 11;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 4px;
}

.bouncing-loader > div {
  width: 6px;
  height: 6px;
  margin: 3px 4px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.4;
    transform: translateY(-4px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
